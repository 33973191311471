<template>
  <div>
    <div v-if="topic">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Audience: <small>{{ topic.title }}</small></h4>
        </div>
      </div>
      <div class="card">
        <div class="rainbow-background"></div>
        <div class="table-responsive">
          <table class="card-table table">
            <tbody class="text-left">
            <tr>
              <th scope="col">ID</th>
              <td>{{ topic.id }}</td>
            </tr>
            <tr>
              <th scope="col">Title</th>
              <td>{{ topic.title }}</td>
            </tr>
            <tr>
              <th scope="col">Is Archived?</th>
              <td>{{ topic.is_archived }}</td>
            </tr>
            <tr>
              <th scope="col">Created At</th>
              <td>{{ new Date(topic.created_at).toDateString() }}, {{ new Date(topic.created_at).toLocaleTimeString() }}</td>
            </tr>
            <tr>
              <th scope="col">Updated At</th>
              <td>{{ new Date(topic.updated_at).toDateString() }}, {{ new Date(topic.created_at).toLocaleTimeString() }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="btn-group float-right">
            <router-link :to="{ name: 'TopicIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
            <router-link :to="{ name: 'TopicEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
            <button v-on:click="$refs.topicDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
          </div>
        </div>
      </div>
      <activity-topics :topic_id="topic.id"></activity-topics>

      <b-modal ref="topicDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteTopic" centered>
        <p>Deleting an audience will delete all Time Card Activities associated to it.</p>
      </b-modal>
    </div>

    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>
import { TopicService } from '@/common/services/topic.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import ActivityTopics from '@/common/components/activity-topics.vue';

export default {
  name: 'TopicShow',
  components: {
    LoadingOverlay,
    ActivityTopics,
  },
  data() {
    return {
      topic: null,
      LoadingOverlay,
    };
  },
  created() {
    this.getTopic();
  },
  methods: {
    getTopic() {
      TopicService.get(this.$route.params.id)
        .then((response) => {
          this.topic = response.data;
        });
    },
    deleteTopic() {
      TopicService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'TopicIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted topic.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
  beforeRouteUpdate(to) {
    TopicService.get(to.params.id)
      .then((response) => {
        this.topic = response.data;
      });
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/topics";
</style>
